<template>
    <div v-if="data.length">
        <div v-for="(item, index) in data" :key="index" class="block" :id="item.point">
            <div v-if="item.layoutType">
                <div v-if="item.title">
                    <div class="block-title">
                        {{ item.title }}
                    </div>
                    <div class="under"></div>
                </div>
                <div v-if="item.detail">
                    <Markdown :data="item.detail"></Markdown>
                </div>
                <component :is="item.layoutType.className" :data="item" />
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
import Markdown from '../markdown';
import {
  cardone, cardtwo, cardthree,
  columnone, columntwo, columnthree, columnfour, columnfive, columnsix, columnseven, columneight, columnnine, columnten,
  homeInfo, sector, timeline, appcourse, subject, contact, librarydir, librarysum, mworkslibrary, mworkstoolbox,
  textone, texttwo, textthree, alltext,
  imageleft, imageright, tabtwo, textleft,
  carouselone, carouseltwo, textright, tabone, columneleven, safe, bugFixed
} from '@/components/block';
export default {
  components: {
    columnone,
    cardone,
    cardtwo,
    columntwo,
    columnsix,
    columnnine,
    columnten,
    homeInfo,
    columnseven,
    sector,
    columnfive,
    textthree,
    carouseltwo,
    carouselone,
    cardthree,
    columnfour,
    timeline,
    textone,
    texttwo,
    columnthree,
    imageleft,
    imageright,
    columneight,
    appcourse,
    subject,
    contact,
    alltext,
    Markdown,
    librarydir,
    librarysum,
    mworkstoolbox,
    mworkslibrary,
    textright,
    textleft,
    tabone,
    tabtwo,
    columneleven,
    safe,
    bugFixed
  },
  props: {
    data: Array
  },
  created() {
    console.log(this.data);
  },
  mounted() {
    const hash = this.$route.hash;
    if (hash) {
      const position = document.querySelector(hash);
      if (position) {
        position.scrollIntoView();
      }
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.block {
    padding-top: 50px;
    padding-bottom: 10px;
}

.clear {
    clear: both;
}

.block-title {
    font-size: 36px;
    text-align: center;
    color: #3e3a39;
    margin-bottom: 20px;
    margin-top: 30px;
}

.under {
    width: 80px;
    height: 6px;
    margin: 0 auto;
    background: #5096ea;
}

@media screen and (max-width: 700px) {
    .block {
        padding: 10px 0;
    }
}
</style>
