<template>
    <div class="body">
        <div class="markdown">
            <MarkDown :mode="mode" v-model="data" />
            <a href="/help/SyslabHelp.html?release=Release_Notes_V0.11.6" v-show="this.$route.path == '/release/syslab'"
                class="release" target="_blank">查看发行说明</a>
            <a href="/help/SysplorerHelp.html?release=Release_Notes_V6.0.7"
                v-show="this.$route.path == '/release/sysplorer'" class="release" target="_blank">查看发行说明</a>
        </div>
        <div v-show="this.$route.path=='/release/syslab'" class="right">
            <div class="position">
                <div style="font-size: 20px;font-weight: bold;margin-bottom: 30px;">资源</div>
                <div style="padding-left: 5px;">
                    <div class="text"><img :src="instructions"><a
                            href="/help/SyslabHelp.html?release=Release_Notes_V0.11.6" target="_blank">发行说明</a></div>
                    <div class="text"><img :src="pdf"><a
                            href="/official/product/deploymentDoc?os=windows&product=syslab">下载Win部署手册</a></div>
                    <div class="text"><img :src="pdf"><a
                            href="/official/product/deploymentDoc?os=linux&product=syslab">下载Cent OS 部署手册</a></div>
                </div>

            </div>
        </div>
        <div v-show="this.$route.path == '/release/sysplorer'" class="right">
            <div class="position">
                <div style="font-size: 20px;font-weight: bold;margin-bottom: 30px;">资源</div>
                <div style="padding-left: 5px;">
                    <div class="text"><img :src="instructions"><a
                            href="/help/SysplorerHelp.html?release=Release_Notes_V6.0.7" target="_blank">发行说明</a></div>
                    <div class="text"><img :src="pdf"><a
                            href="/official/product/deploymentDoc?os=windows&product=sysplorer">下载Win部署手册</a></div>
                    <div class="text"><img :src="pdf"><a
                            href="/official/product/deploymentDoc?os=linux&product=sysplorer">下载Cent OS部署手册</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cent from '../../assets/svg/cent.svg';
import win from '../../assets/svg/win.svg';
import pdf from '../../assets/svg/pdf.svg';
import instructions from '../../assets/svg/instructions.svg';
import MarkDown from '@/components/markdown';
export default {
  components: {
    MarkDown
  },
  props: {
    data: String
  },
  data() {
    return {
      mode: 'preview',
      cent,
      win,
      pdf,
      instructions
    };
  },
  methods: {
  },
  created() {
    console.log('realease页面', this.data);
    console.log('this.$route.path', this.$route.path);
  }

};
</script>

<style lang="scss" scoped>
.body {
    // display: block;
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 10px auto 60px auto;
    justify-content: space-between;
}
.markdown{
    // width: 78%;
    flex: 4;

}
.right{
    // width: 20%;
    flex: 1;
}
.position{
    position: sticky;
    top:130px;
    // border:2px solid #AAAAAA;
    // border-radius: 10px;
    padding: 17px 10px 21px 21px;
}
.text{
    margin-bottom: 10px;
    // vertical-align: middle;
    // line-height: 35px;
    // font-size: 13px;
    img{
        width: 23px;
        vertical-align: middle;
    }
    a{
        font-size: 15px;
        line-height: 40px;
        margin-left: 15px;
        color: #497ddc
        ;
        vertical-align: middle;
    }
}
.release {
    border: 1px solid #4577bb;
    color: #4577bb;
    border-radius: 4px;
    padding: 8px 15px;
    font-size: 18px;
    margin-left: 30px;
}
@media screen and (max-width: 700px) {
    .right{
        width: 22%;
    }
    .text{
        margin-bottom: 0.2vh;
        img{
            width: 1.8vw;
        }
        a{
            font-size: 1.6vw;
            margin-left: 0.8vw;
        }
    }
}

</style>
