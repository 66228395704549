<template>
  <v-md-editor
    v-model="text"
    :disabled-menus="[]"
    :height="height"
    mode="edit"
    @upload-image="uploadImage"
    @change="change"
    @image-click="imageClick"
    @fullscreen-change="fullscreenChange"
  ></v-md-editor>
</template>

<script>
export default {
  props: {
    value: String,
    height: {
      type: String,
      default: '600px'
    }
  },
  data() {
    return {};
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    uploadImage(event, insertImage, files) {
      // 拿到 files 之后上传到文件服务器，然后向编辑框中插入对应的内容
      // console.log(files);
      const file = files[0];
      const formData = new FormData();
      formData.append('bizPath', 'question');
      formData.append('file', file);
      this.$api.upload(formData).then((res) => {
        if (res.code == 0) {
          insertImage({
            url: window.location.origin + '/mohub/resource/' + res.data,
            desc: file.name
            // width: 'auto',
            // height: 'auto',
          });
        }
      });
    },
    change(text, html) {
      this.$emit('change', text, html);
    },
    imageClick(images, currentIndex) {
      console.log(images, currentIndex);
      const img = images[0];
      const returnUrl = img.split('returnUrl=')[1];
      if (returnUrl) {
        this.$router.push(returnUrl);
      }
      this.$emit('image-click', images, currentIndex);
    },
    fullscreenChange(isFullscreen) {
      // 解决全屏 工具栏被 header 遮挡问题
      console.log(isFullscreen, 'isFullscreen');
      const header = document.querySelector('.el-header');
      if (isFullscreen) {
        header.style.zIndex = 1;
      } else {
        header.style.zIndex = 10;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-md-editor {
  box-shadow: none;
  border: 1px solid #e5e5e5;
}
::v-deep .github-markdown-body li {
  list-style-type: disc !important;
}
</style>
