<template>
  <component
    :is="currentComponent"
    v-model="text"
    :height="height"
    :showNav="showNav"
    @change="change"
    @image-click="imageClick"
  ></component>
</template>

<script>
import VMdEditor from './editor.vue';
import VMdPreview from './preview.vue';
export default {
  name: 'Markdown',
  props: {
    value: String,
    height: String,
    mode: {
      type: String,
      default: 'preview'
    },
    showNav: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VMdEditor,
    VMdPreview
  },
  data() {
    return {};
  },
  computed: {
    currentComponent() {
      return this.mode == 'preview' ? 'VMdPreview' : 'VMdEditor';
    },
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    change(text, html) {
      this.$emit('change', text, html);
    },
    imageClick(images, currentIndex) {
      console.log(images, currentIndex);
      this.$emit('image-click', images, currentIndex);
    }
  }
};
</script>
<style lang="scss" scoped></style>
