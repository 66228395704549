<template>
  <div class="v-md-editor">
    <div class="v-md-editor__left-area" v-if="showNav && titles.length">
      <!-- <div
        class="v-md-editor__left-area-title"
        style="height: 60px; line-height: 60px;"
      >
        目录导航
      </div> -->
      <div class="v-md-editor__left-area-body">
        <div class="scrollbar">
          <div
            class="scrollbar__wrap"
            style="margin-bottom: 0px; margin-right: 0px;"
          >
            <div class="scrollbar__view">
              <ul class="v-md-editor__toc-nav">
                <li
                  class="v-md-editor__toc-nav-item"
                  v-for="anchor in titles"
                  @click="handleAnchorClick(anchor)"
                >
                  <span
                    class="v-md-editor__toc-nav-title"
                    :title="anchor.title"
                    >{{ anchor.title }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="scrollbar__bar is-horizontal">
            <div
              class="scrollbar__thumb"
              style="transform: translateX(0%);"
            ></div>
          </div>
          <div class="scrollbar__bar is-vertical">
            <div
              class="scrollbar__thumb"
              style="transform: translateY(0%);"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="v-md-editor__right-area">
      <div class="v-md-editor__main">
        <v-md-preview :text="value" ref="preview" @image-click="imageClick" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    showNav: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      titles: []
    };
  },
  watch: {
    value(n, o) {
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        const anchors = this.$refs.preview.$el.querySelectorAll(
          'h1,h2,h3,h4,h5,h6'
        );
        const titles = Array.from(anchors).filter(
          title => !!title.innerText.trim()
        );

        if (!titles.length) {
          this.titles = [];
          return;
        }

        const hTags = Array.from(
          new Set(titles.map(title => title.tagName))
        ).sort();

        this.titles = titles.map(el => ({
          title: el.innerText,
          lineIndex: el.getAttribute('data-v-md-line'),
          indent: hTags.indexOf(el.tagName)
        }));
      });
    },
    handleAnchorClick(anchor) {
      const preview = this.$refs.preview;
      const { lineIndex } = anchor;

      const heading = preview.$el.querySelector(
        `[data-v-md-line="${lineIndex}"]`
      );

      if (heading) {
        preview.scrollToTarget({
          target: heading,
          scrollContainer: window,
          top: 110
        });
      }
    },
    imageClick(images, currentIndex) {
      console.log(images, currentIndex);
      const img = images[0];
      const returnUrl = img.split('returnUrl=')[1];
      if (returnUrl) {
        this.$router.push(returnUrl);
      }
      this.$emit('image-click', images, currentIndex);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-md-editor {
  box-shadow: none;
}
.v-md-editor__left-area {
  width: 200px;
  border-width: 1px;
  height: auto;
}
.v-md-editor__toc-nav-item {
  list-style: initial;
  list-style-position: inside;
}
::v-deep .github-markdown-body li {
  list-style-type: disc !important;
}
</style>
